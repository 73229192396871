<template>
  <v-card>
      <v-card-title :class="`${color}--text`">
          {{ title | t }}
      </v-card-title>
      <v-card-text v-html="$options.filters.t(text, name)">
          <!-- {{ text | t(name) }} -->
      </v-card-text>
      <v-card-actions>
          <v-btn :color="color" rounded outlined @click="confirm" :loading="loading">
              <v-icon small>{{ confirmIcon}}</v-icon>
              {{ confirmText | t }}
          </v-btn>
          <v-btn  outlined rounded @click="cancel" :disabled="loading">
              <v-icon small >{{ cancelIcon }}</v-icon>
              {{ cancelText | t }}
          </v-btn>
      </v-card-actions>
  </v-card>
</template>

<script>
export default {
    props: {
        name: String,
        title: String,
        text: String,
        color: {
            type: String,
            default: 'success'
        },
        confirmText: {
            type: String,
            default: 'confirm'
        },
        confirmIcon: {
            type: String,
            default: 'mdi-check'
        },
        cancelText: {
            type: String,
            default: 'cancel'
        },
        cancelIcon: {
            type: String,
            default: 'mdi-close'
        },
        loading: Boolean
    },
    methods: {
        confirm () {
            this.$emit('confirm')
        },
        cancel () {
            this.$emit('cancel')
        }
    }
}
</script>

<style>

</style>